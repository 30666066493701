import { getSession } from "next-auth/client";
import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import redirects from "../../src/api/redirects";

const api = (() => {
  let originalObj = null;
  let newObj = null;
  let status = null;

  const parseErrors = (response) => {
    console.log("RESPONSE", response);
  };

  const logout = () => {
    console.log("must redirect");
    return redirects.redirectTo("/unauthorized");
  };

  const s = (obj, returnWithStatus = false, firstIteration = true) => {
    if (!obj) return;

    if (firstIteration) originalObj = obj;

    if (
      !obj.hasOwnProperty("data") &&
      !obj.hasOwnProperty("errors") &&
      !obj.hasOwnProperty("debugError")
    ) {
      let nextProperty = null;
      for (let property in obj) {
        nextProperty = property;
      }

      newObj = obj[nextProperty];
      status = 1;
    }

    if (obj.hasOwnProperty("debugError")) {
      newObj = obj.debugError;
      status = 2;
    }

    if (obj.hasOwnProperty("errors")) {
      newObj = parseErrors(originalObj);
      status = 3;
    }

    if (obj.hasOwnProperty("data")) {
      newObj = obj.data;
      s(newObj, returnWithStatus, false);
    }

    if (returnWithStatus) return { status: status, data: newObj };

    return newObj;
  };

  let queryProto = {
    client: async function (context = null, token = null) {
      //console.log( 'is there any context?', context )
      //console.log( 'is there any token? ', tokenFromSession?.user?.image?.access_token ?? token  )
      //console.log(  'any query?', this )

      const tokenFromSession = context ? await getSession(context) : token;
      //console.log( 'tokenFromSession', tokenFromSession )

      const httpLink = createHttpLink({
        uri: process.env.GRAPHQL,
      });

      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${
              tokenFromSession?.user?.image?.access_token ?? token
            }`,
          },
        };
      });

      const client = new ApolloClient({
        ssrMode: typeof window === "undefined",
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      });

      return client;
    },

    doMutation: async function (context = null, token = null) {
      let client = await this.client(context, token);
      let data = await client.mutate({
        mutation: gql`
          ${this.query}
        `,
      });

      return s(data);
    },

    doQuery: async function (context = null, token = null, isRaw = false) {
      let client = await this.client(context, token);

      let data = null;

      try {
        data = await client.query({
          query: gql`
            ${this.query}
          `,
        });
      } catch (e) {
        console.log("🚨 this query has en error:", this.query);
      }

      return isRaw ? data : s(data);

      /*try {
        data = await client.query({
          query: gql`${this.query}`
        })
        
        return s(data)

      } catch (err) {
        if ( err?.graphQLErrors[0]?.message === 'Unauthenticated.' )
          return {
            redirect: {
                permanent: false,
                destination: '/unauthorized'
            }
          }
      }*/
    },
  };

  const GetModuleById = function (moduleId) {
    this.query = `
    query {
      getModuleById(id: ${moduleId}) {
        id
        title
        diploma {
          id
          font_size
          pos_x
          pos_y
          color
          url
        }
      }
    }
    `;
  };
  GetModuleById.prototype = queryProto;
  GetModuleById.prototype.Constructor = GetModuleById;

  const GetCourseById = function (courseId) {
    this.query = `
    query {
      getCourseById(id: ${courseId}) {
        id
        title
        slug
        diploma {
          id
          font_size
          pos_x
          pos_y
          color
          url
        }
      }
    }
    
    `;
  };
  GetCourseById.prototype = queryProto;
  GetCourseById.prototype.Constructor = GetCourseById;

  const GetDiplomaVideo = function (videoId) {
    this.query = `query {
      getVideoById(id: ${videoId}) {
        id
        title
        slug
        diploma {
          id
          font_size
          pos_x
          pos_y
          color
          url
        }
      }
    }
    `;
  };
  GetDiplomaVideo.prototype = queryProto;
  GetDiplomaVideo.prototype.Constructor = GetDiplomaVideo;

  const GetTopics = function () {
    this.query = `query {
      getTopics(
        orderBy: [
          {
          column: "order"
          order: ASC
          }
      ]
        available: 1) {
        id
        name
        slug
        image
      }
    }`;
  };
  GetTopics.prototype = queryProto;
  GetTopics.prototype.Constructor = GetTopics;

  const GetCategories = function () {
    this.query = `query {
      GetCategories {
        id
        name
        slug
        image
      }
    }`;
  };
  GetCategories.prototype = queryProto;
  GetCategories.prototype.Constructor = GetCategories;

  const GetLastEvents = function () {
    this.query = `query {
      GetLastEvents {
        id
        title
        slug
        banner_url
      }
    }`;
  };
  GetLastEvents.prototype = queryProto;
  GetLastEvents.prototype.Constructor = GetLastEvents;

  const GetLiveEvents = function () {
    this.query = `query {
      GetLiveEvents {
          id
          title
          slug
          upcoming_thumb_url
      }
    }`;
  };
  GetLiveEvents.prototype = queryProto;
  GetLiveEvents.prototype.Constructor = GetLiveEvents;

  const GetInstatagUser = function (userId) {
    this.query = `query {
      user(id: ${userId}) {
        id
        name
        email
        specialty {
          name
        }
      }
    }`;
  };
  GetInstatagUser.prototype = queryProto;
  GetInstatagUser.prototype.Constructor = GetInstatagUser;

  const IsEventInWhiteList = function (eventID) {
    this.query = `query {
      IsEventInWhiteList( event_id: ${eventID})
    }`;
  };
  IsEventInWhiteList.prototype = queryProto;
  IsEventInWhiteList.prototype.Constructor = IsEventInWhiteList;

  const GetCalendarSlider = function () {
    this.query = ` query {
      GetCalendarSlider {
        id2
        title
        image
        link
        type
        status
      }
    }`;
  };
  GetCalendarSlider.prototype = queryProto;
  GetCalendarSlider.prototype.Constructor = GetCalendarSlider;

  const GetMainSlider = function () {
    this.query = `query {
      GetMainSlider {
        title
        slug
        image
        type
        event_slug
      }
    }`;
  };
  GetMainSlider.prototype = queryProto;
  GetMainSlider.prototype.Constructor = GetMainSlider;

  const GetVodEvents = function () {
    this.query = `query {
      GetVodEvents {
        id
        title
        slug
        poster_url
        total_videos
        diploma_type
        certification {
          id
          points(value: "points")
          titles: data(value: "title")
          images: data(value: "url")
        }
      }
    }`;
  };
  GetVodEvents.prototype = queryProto;
  GetVodEvents.prototype.Constructor = GetVodEvents;

  const GetRecentVideos = function () {
    this.query = `query {
      GetRecentVideos {
        id
        title
        slug
        thumbnail_url
        likes
        event_slug
        event_type
        authors {
          name
        }
      }
    }`;
  };
  GetRecentVideos.prototype = queryProto;
  GetRecentVideos.prototype.Constructor = GetRecentVideos;

  const GetUpcomingEvents = function () {
    this.query = `query {
      GetUpcomingEvents {
        id
        title
        slug
        upcoming_thumb_url
        start_date
        has_confirmed
        certification {
          id
          points(value: "points")
          titles: data(value: "title")
          images: data(value: "url")
        }
      }
    }`;
  };
  GetUpcomingEvents.prototype = queryProto;
  GetUpcomingEvents.prototype.Constructor = GetUpcomingEvents;

  const GetFirstVideo = function (ID) {
    this.query = `query {
      GetFirstVideo(event_id: ${ID}) {
        id
        title
        slug
        thumbnail_url
        kaltura_id
        translated_kaltura_id
        full_kaltura_id
        full_translated_kaltura_id

        full_playback_id
        full_translated_playback_id

        start_date
        tags
        duration
        transcript_url
        
        courses {
          id
          slug
        }

        category {
          id
          name
        }
      }
    }`;
  };
  GetFirstVideo.prototype = queryProto;
  GetFirstVideo.prototype.Constructor = GetFirstVideo;

  const GetFirstEpisode = function (ID) {
    this.query = `query {
      GetFirstEpisode(podcast_id: ${ID}) {
        id 
        title
        slug
        publish_date
        tags
        thumbnail
        duration
        playback_id
        full_playback_id
        order
        category {
          id
        }
      }
    }`;
  };
  GetFirstEpisode.prototype = queryProto;
  GetFirstEpisode.prototype.Constructor = GetFirstEpisode;

  const GetVideoById = function (ID) {
    this.query = `query {
      getVideoById(id: ${ID}) {
        id
        title
        kaltura_id
        translated_kaltura_id
        tags
        wirewax
        wirewax_translated
        duration
      }
    }`;
  };
  GetVideoById.prototype = queryProto;
  GetVideoById.prototype.Constructor = GetVideoById;

  const GetVideoEvent = function (ID) {
    this.query = `query {
      getVideoEvent(id: ${ID}) {
        id
        total_videos
      }
    }`;
  };
  GetVideoEvent.prototype = queryProto;
  GetVideoEvent.prototype.Constructor = GetVideoEvent;

  const GetVideoAuthors = function (ID) {
    this.query = `query {
      GetVideoAuthors( video_id: ${ID} ) {
        id
        name
        specialty
        cv
        image
      }
    }`;
  };
  GetVideoAuthors.prototype = queryProto;
  GetVideoAuthors.prototype.Constructor = GetVideoAuthors;

  const GetAssistancesHome = function () {
    this.query = `query {
      GetAssistances {
        id
        event {
          id
        }
      }
    }`;
  };
  GetAssistancesHome.prototype = queryProto;
  GetAssistancesHome.prototype.Constructor = GetAssistancesHome;

  const GetRegisterDropdownValues = function (name) {
    this.query = `query {
      ${name} {
        id
        name
      }
    }`;
  };
  GetRegisterDropdownValues.prototype = queryProto;
  GetRegisterDropdownValues.prototype.Constructor = GetRegisterDropdownValues;

  const GetUserPDF = function (userID) {
    this.query = `query {
      user(id: ${userID}) {
        id
        specialty {
          id
        }
      }
    }`;
  };
  GetUserPDF.prototype = queryProto;
  GetUserPDF.prototype.Constructor = GetUserPDF;

  const IsEventSerie = function (userID) {
    this.query = `query {
      IsEventSerie(eventID: ${userID})
    }
           `;
  };
  IsEventSerie.prototype = queryProto;

  const GetAllSerieProgress = function (eventID) {
    this.query = `query {
      GetAllSerieProgress(
        eventID: ${eventID} 
      ) 
    }`;
  };
  GetAllSerieProgress.prototype = queryProto;

  const GetLiveAlatProgress = function () {
    this.query = `query {
      GetLiveAlatProgress
    }`;
  };
  GetLiveAlatProgress.prototype = queryProto;

  const GetEventBySlugPDF = function (slug) {
    this.query = `query {
      GetEventBySlugPDF( slug: "${slug}" ) {
        id
        title
        slug
        type
        status
        total_videos
        diploma_type
        upcoming_thumb_url
        start_date
        finish_date
        description
        duration
        typeform_id
        poll_id
        live_playback_id
        live_translated_playback_id
        addevent_id
        day
        agenda_url
        itg_url
        is_regional
        
        category {

          name
        }

        diploma {
          id
        }

        new_diploma

        banners {
          banner_url
          url
          device
        }

        exam {
          id
          exam_typeform_id
          min_approbatory
        }

      }
    }`;
  };
  GetEventBySlugPDF.prototype = queryProto;
  GetEventBySlugPDF.prototype.Constructor = GetEventBySlugPDF;

  const GetEventBySlug = function (slug) {
    this.query = `query {
      GetEventBySlug( slug: "${slug}" ) {
        id
        title
        slug
        type
        status
        total_videos
        diploma_type
        upcoming_thumb_url
        start_date
        finish_date
        description
        duration
        typeform_id
        poll_id
        live_playback_id
        live_translated_playback_id
        full_live_playback_id
        full_live_translated_playback_id
        addevent_id
        day
        agenda_url
        itg_url
        is_regional
        show_countdown
        
        category {
          name
        }

        diploma {
          id
        }

        new_diploma

        current_banner {
          id
          desktop_banner_url
          mobile_banner_url
          type
          url      
        } 

        exam {
          id
          exam_typeform_id
          min_approbatory
        }

        diploma_serie {
          id
          name
          description
          min_play
          diploma {
            id
          }
        }
        show_series_videos
        series_icon
        new_diploma_rule
        second_typeform_id
      }
    }`;
  };
  GetEventBySlug.prototype = queryProto;
  GetEventBySlug.prototype.Constructor = GetEventBySlug;

  const GetEventVideos = function (currentVideoID, eventID) {
    this.query = `query {
      GetEventVideos ( current_video_id: ${currentVideoID}, event_id: ${eventID} ) {
        id
        title
        slug
        thumbnail_url
        start_date

        authors {
          name
        }
        event {
          slug
        }
      }
    }`;
  };
  GetEventVideos.prototype = queryProto;
  GetEventVideos.prototype.Constructor = GetEventVideos;

  const GetPodcastEpisodes = function (currentEpisodeID, podcastID) {
    this.query = `query {
      GetPodcastEpisodes(
        current_episode_id: ${currentEpisodeID}
        podcast_id: ${podcastID}
      ) {
        id
        title
        slug
        publish_date
        tags
        thumbnail
        playback_id
        full_playback_id
        order
        duration
        authors {
          name
        }
      }
    }
    `;
  };
  GetPodcastEpisodes.prototype = queryProto;
  GetPodcastEpisodes.prototype.Constructor = GetPodcastEpisodes;

  //"lorem,ipsum"
  const GetRelatedVideos = function (
    tags,
    currentVideoID,
    event_id,
    categoryID,
    hide_from_same_event
  ) {
    this.query = `query {
      GetRelatedVideos(tags: "${tags}", current_video_id: ${currentVideoID}, event_id: ${event_id} hide_from_same_event: ${hide_from_same_event} category_id: ${categoryID} ) {
          id
          title
          slug
          thumbnail_url
          authors {
            name
          }
          event {
            slug
          }
      }
    }`;
  };
  GetRelatedVideos.prototype = queryProto;
  GetRelatedVideos.prototype.Constructor = GetRelatedVideos;

  const GetVideoBySlug = function (videoSlug, eventID) {
    this.query = `query {
      GetVideoBySlug(
        video_slug: "${videoSlug}"
        event_id: ${eventID}
      ) {
        id
        title
        slug
        thumbnail_url
        translated_kaltura_id
        kaltura_id
        full_translated_kaltura_id
        full_kaltura_id
        full_playback_id
        full_translated_playback_id

        duration
        transcript_url

        courses {
          id
          slug
        }

        category {
          id
          name
        }
        start_date
        tags
      }
    }`;
  };
  GetVideoBySlug.prototype = queryProto;
  GetVideoBySlug.prototype.Constructor = GetVideoBySlug;

  const GetEpisodeBySlug = function (podcastSlug) {
    this.query = `query {
      GetEpisodeBySlug(
        podcast_slug: "${podcastSlug}"
      ) {
        id
         title
          slug
          publish_date
          tags
          duration
          thumbnail
          playback_id
          full_playback_id
          order
          category {
            id
          }
      }
    }`;
  };
  GetEpisodeBySlug.prototype = queryProto;
  GetEpisodeBySlug.prototype.Constructor = GetEpisodeBySlug;

  //My videos:
  const GetAssistances = function () {
    this.query = `query {
      GetAssistances {
        id
        event {
          id
          slug
          title
          status
          upcoming_thumb_url
          poster_url
          banner_url
          start_date

        }
      }
    }`;
  };
  GetAssistances.prototype = queryProto;
  GetAssistances.prototype.Constructor = GetAssistances;

  const GetBookmarks = function () {
    this.query = `query {

      GetBookmarks {
        id
        title
        slug
        thumbnail_url
        likes

        event {
          slug
          
          course {
            slug
          }
        }

        authors {
          name
        }
      }
    }`;
  };
  GetBookmarks.prototype = queryProto;
  GetBookmarks.prototype.Constructor = GetBookmarks;

  const GetPodcastBookmarks = function () {
    this.query = `query {
      GetPodcastBookmarks {
        id
        title
        slug
        thumbnail
        likes
    
        podcast {
          slug
        }
    
        authors {
          name
        }
      }
    }`;
  };
  GetPodcastBookmarks.prototype = queryProto;
  GetPodcastBookmarks.prototype.Constructor = GetPodcastBookmarks;

  const GetContinueWatching = function () {
    this.query = `query {
      GetContinueWatching {
        id
        percentage

        video {
          id
          title
          slug
          thumbnail_url
          likes

          event {
            slug

            course {
              slug
            }
          }

          authors {
            name
          }
        }
      }
    }`;
  };
  GetContinueWatching.prototype = queryProto;
  GetContinueWatching.prototype.Constructor = GetContinueWatching;

  const GetWatchedHistory = function () {
    this.query = `query {
      GetWatchedHistory {
        id
        played_at

        video {
          id
          title
          slug
          thumbnail_url
          likes

          event {
            slug

            course {
              slug
            }
          }

          authors {
            name
          }
        }
      }
    }`;
  };
  GetWatchedHistory.prototype = queryProto;
  GetWatchedHistory.prototype.Constructor = GetWatchedHistory;

  const GetDownloadedDiplomas = function () {
    this.query = `query {
        GetDownloadedDiplomas {
          id
          percentage
          downloaded_at

          diploma {
            id
          }

          event {
            id
            title
            slug

            course {
              slug
            }
          }

          course {
            id
            title
            slug
          }

        }
      }`;
  };
  GetDownloadedDiplomas.prototype = queryProto;
  GetDownloadedDiplomas.prototype.Constructor = GetDownloadedDiplomas;

  const BrowseEvent = function (slug) {
    this.query = `query {
      BrowseEvent( event_slug: "${slug}" ) {
        id
        title
        total_videos
        banner_url
        poster_url
      }
    }`;
  };
  BrowseEvent.prototype = queryProto;
  BrowseEvent.prototype.Constructor = BrowseEvent;

  //likes, views, start_date
  const BrowseEventVideos = function (eventID, orderBy) {
    this.query = `query {
      BrowseEventVideos(
        event_id: ${eventID}
        order_by: "${orderBy}"
      ) {
        id
        title
        slug
        thumbnail_url
        likes

        event {
          slug

          course {
            slug
          }
        }

        authors {
          name
        }
      }
    }`;
  };
  BrowseEventVideos.prototype = queryProto;
  BrowseEventVideos.prototype.Constructor = BrowseEventVideos;

  const BrowseCategory = function (slug) {
    this.query = `query {
      BrowseCategory( category_slug: "${slug}" ) {
        id
        name
        image
      }
    }`;
  };
  BrowseCategory.prototype = queryProto;
  BrowseCategory.prototype.Constructor = BrowseCategory;

  //likes, views, start_date
  const BrowseCategoryVideos = function (categoryID, orderBy) {
    this.query = `query {
      BrowseCategoryVideos(
        category_id: ${categoryID}
        order_by: "${orderBy}"
      ) {
        id
        title
        slug
        thumbnail_url
        likes

        event {
          slug

          course {
            slug
          }
        }

        authors {
          name
        }

      }
    }`;
  };
  BrowseCategoryVideos.prototype = queryProto;
  BrowseCategoryVideos.prototype.Constructor = BrowseCategoryVideos;

  const BrowseTopic = function (slug) {
    this.query = `query {
      BrowseTopic( topic_slug: "${slug}" ) {
        id
        name
        image
      }
    }`;
  };
  BrowseTopic.prototype = queryProto;
  BrowseTopic.prototype.Constructor = BrowseTopic;

  //likes, views, start_date
  const BrowseTopicVideos = function (topicID, orderBy) {
    this.query = `query {
      BrowseTopicVideos(
        topic_id: ${topicID}
        order_by: "${orderBy}"
      ) {
        id
        title
        slug
        thumbnail_url
        likes

        event {
          slug
          
          course {
            slug
          }
        }

        authors {
          name
        }
      }
    }`;
  };
  BrowseTopicVideos.prototype = queryProto;
  BrowseTopicVideos.prototype.Constructor = BrowseTopicVideos;

  //category_id, event_id, topic_id
  const BrowseCount = function (ID, where) {
    this.query = `query {
      BrowseCount(
         id: ${ID}
        where: "${where}"
      )
    }`;
  };
  BrowseCount.prototype = queryProto;
  BrowseCount.prototype.Constructor = BrowseCount;

  //AUTH
  const Login = function (email, password) {
    this.query = `mutation {
      Login(
        email: "${email}",
        password: "${password}"
      ) {
        access_token
        refresh_token
        expires_in
        token_type
        status
        message
        user {
          id
          license_verified
          role
          specialty {
            id
          }
          state {
            id
          }
          working_place_text
        }
      }
    }`;
  };
  Login.prototype = queryProto;
  Login.prototype.Constructor = Login;

  const Register = function (
    name,
    second_last_name,
    email,
    email_confirmation,
    password,
    password_confirmation,
    phone,
    specialty_id,
    country_id,
    working_place_id,
    state_id,
    last_name,
    license,
    institution_id,
    other_institution,
    medical_units,
    sanitary_jurisdiction_id,
    role,
    agree,
    working_place_text,
    regional,
    consulting,
    is_resident,
    resident_year
  ) {
    this.query = `mutation {
      Register(
        name: "${name}"
        second_last_name: "${second_last_name}"
        email: "${email}"
        email_confirmation: "${email_confirmation}"
        password: "${password}"
        password_confirmation: "${password_confirmation}"
        phone: "${phone}"
        specialty_id: "${specialty_id}"
        country_id: "${country_id}"
        working_place_id: "${working_place_id}"
        state_id: "${state_id}"
        last_name: "${last_name}"
        license: "${license}"
        institution_id: "${institution_id}"
        other_institution: "${other_institution}"
        medical_units: "${medical_units}"
        sanitary_jurisdiction_id: "${sanitary_jurisdiction_id}"
        role: ${role}
        agree: "${agree}"
        working_place_text: "${working_place_text}"

        regional_place_id: "${regional}"
        consulting_place_id: "${consulting}"
        is_phone_required: true
        platform: "web"
        is_resident: ${is_resident}
        resident_year: "${resident_year}" 
      )
      {
        access_token
        refresh_token
        expires_in
        token_type
        status
        message
        user {
          id
          license_verified
          role,
          specialty {
            id
          }
          state {
            id
          }
          working_place_text
        }
      }
    }`;
  };
  Register.prototype = queryProto;
  Register.prototype.Constructor = Register;

  const Logout = function () {
    this.query = `mutation {
      logout {
        status
        message
      }
    }`;
  };
  Logout.prototype = queryProto;
  Logout.prototype.Constructor = Logout;

  const ForgotPassword = function (email) {
    this.query = `mutation {
      ForgotPassword(
        email: "${email}"
      ) {
        message
        status
      }
    }`;
  };
  ForgotPassword.prototype = queryProto;
  ForgotPassword.prototype.Constructor = ForgotPassword;

  const UpdateForgottenPassword = function (
    email,
    token,
    password,
    password_confirmation
  ) {
    this.query = `mutation {
      UpdateForgottenPassword(
        email: "${email}"
        token: "${token}"
        password: "${password}"
        password_confirmation: "${password_confirmation}"
      ) {
        message
        status
      }
    }
    `;
  };
  UpdateForgottenPassword.prototype = queryProto;
  UpdateForgottenPassword.prototype.Constructor = UpdateForgottenPassword;

  const ResendConfirmationLink = function (email) {
    this.query = `mutation {
      ResendConfirmationLink(
        email: "${email}"
      ) {
        status
        message
      }
    }
    `;
  };
  ResendConfirmationLink.prototype = queryProto;
  ResendConfirmationLink.prototype.Constructor = ResendConfirmationLink;

  const VerifyEmail = function (requestID, requestHash) {
    this.query = `mutation {
      VerifyEmail(
        requestID: ${requestID}
        requestHash: "${requestHash}"
      ) {
        status
        message
      }
    }
    `;
  };
  VerifyEmail.prototype = queryProto;
  VerifyEmail.prototype.Constructor = VerifyEmail;
  //AUTH

  const GetGeneralDiplomaProgress = function (eventID) {
    this.query = `query {
      GetGeneralDiplomaProgress(
        event_id: ${eventID}
      ) {
        id
        percentage
      }
    }
    `;
  };
  GetGeneralDiplomaProgress.prototype = queryProto;
  GetGeneralDiplomaProgress.prototype.Constructor = GetGeneralDiplomaProgress;

  const GetIndividualDiplomaProgress = function (eventID) {
    this.query = `query {
      GetIndividualDiplomaProgress(
        event_id: ${eventID}
      ) {
        id
        percentage
        status

        video {
          id
          title
          slug
          thumbnail_url

          authors {
            name
          }
        }
        event {
          slug
        }
      }
    }
    `;
  };
  GetIndividualDiplomaProgress.prototype = queryProto;
  GetIndividualDiplomaProgress.prototype.Constructor =
    GetIndividualDiplomaProgress;

  const GetClinicalCases = function () {
    this.query = `query {
      GetClinicalCases {
        id
        title
        slug
        abstract
        poster
      }
    }`;
  };
  GetClinicalCases.prototype = queryProto;
  GetClinicalCases.prototype.Constructor = GetClinicalCases;

  const GetClinicalCaseBySlug = function (slug) {
    this.query = `query {
      GetClinicalCaseBySlug(slug: "${slug}") {
        id
        title
        slug
        description
        link
        poster
        category {
          id
          name
          image
        }
        provider
      }
    }`;
  };
  GetClinicalCaseBySlug.prototype = queryProto;
  GetClinicalCaseBySlug.prototype.Constructor = GetClinicalCaseBySlug;

  const AlgoliaEventSearch = function (query) {
    this.query = `query {
      AlgoliaEventSearch(
        query: "${query}"
        ) {
        id
        title
        slug
        poster_url
        status
        upcoming_thumb_url
        banner_url
      }
    }
    `;
  };
  AlgoliaEventSearch.prototype = queryProto;
  AlgoliaEventSearch.prototype.Constructor = AlgoliaEventSearch;

  const AlgoliaVideoSearch = function (query) {
    this.query = `query {
      AlgoliaVideoSearch(
        query: "${query}"
        ) {
        id
        title
        slug
        thumbnail_url

        category {
          name
        }

        authors {
          name
        }

        event {
          slug
        }
      }
    }
    `;
  };
  AlgoliaVideoSearch.prototype = queryProto;
  AlgoliaVideoSearch.prototype.Constructor = AlgoliaVideoSearch;

  //tracking
  const GetCurrentTalk = function (eventID, day) {
    this.query = `query {
      GetCurrentTalk(
        event_id: ${eventID}
        day: ${day}
      ) {
        id
        duration
        shoud_update
      }
    }`;
  };
  GetCurrentTalk.prototype = queryProto;
  GetCurrentTalk.prototype.Constructor = GetCurrentTalk;

  const GetCurrentTalkBreakout = function (eventID, day, videoID) {
    this.query = `query {
      GetCurrentTalkBreakout(
        event_id: ${eventID}
        day: ${day}
        video_id: ${videoID}
      ) {
         id
        duration
        shoud_update
      }
    }`;
  };
  GetCurrentTalkBreakout.prototype = queryProto;
  GetCurrentTalkBreakout.prototype.Constructor = GetCurrentTalkBreakout;

  const CreateView = function (
    status,
    media,
    mode,
    channel,
    duration,
    videoID,
    eventID,
    sessionID,
    source,
    medium,
    campaign
  ) {
    this.query = `mutation {
      CreateView (
        status: "${status}"
        media: "${media}"
        mode: "${mode}"
        channel:"${channel}"
        duration: ${duration}
        video_id: ${videoID}
        event_id: ${eventID}
        session_id: ${sessionID}

        source: "${source}"
        medium: "${medium}"
        campaign: "${campaign}"
      )
    }`;
  };
  CreateView.prototype = queryProto;
  CreateView.prototype.Constructor = CreateView;

  const CreateDiplomaReport = function (eventID, diplomaID) {
    this.query = `mutation {
      CreateDiplomaReport (
        event_id: ${eventID}
        diploma_id: ${diplomaID}
      )
    }`;
  };
  CreateDiplomaReport.prototype = queryProto;
  CreateDiplomaReport.prototype.Constructor = CreateDiplomaReport;

  const GetLastCurrentTime = function (videoID) {
    this.query = `query {
      GetLastCurrentTime(
        video_id: ${videoID}
      ) {
        id
        current_time
      }
    }`;
  };
  GetLastCurrentTime.prototype = queryProto;
  GetLastCurrentTime.prototype.Constructor = GetLastCurrentTime;

  const UpdateView = function (
    milliseconds,
    videoID,
    eventID,
    status,
    duration,
    media,
    mode,
    sessionID,
    totalVideos,
    channel,
    current_time,
    shoud_update = true
  ) {
    this.query = `mutation {
      UpdateView(
        milliseconds: ${milliseconds}
        video_id: ${videoID}
        event_id: ${eventID}
        status: "${status}"
        duration: ${duration}
        media: "${media}"
        mode: "${mode}"
        session_id: ${sessionID}
        total_videos: ${totalVideos}
        channel: "${channel}"
        current_time: ${current_time}
        shoud_update: ${shoud_update}
      )
    }`;
  };
  UpdateView.prototype = queryProto;
  UpdateView.prototype.Constructor = UpdateView;
  //tracking

  const GetUser = function (userID) {
    this.query = `query {
      user(id:${userID}) {
        name
        last_name
        second_last_name
      }
    }
  `;
  };
  GetUser.prototype = queryProto;
  GetUser.prototype.Constructor = GetUser;

  const GetProfileUser = function (userID) {
    this.query = `query {
      GetProfileUser(user_id: ${userID}) {
        id
    email
    name
    last_name
    second_last_name
    license
    phone

    specialty {
      id
    }

    country {
      id
    }

    state {
      id
    }

    working

    working_place_text

    institution {
      id
    }

    other_institution

    medical_units

    sanitary
    is_resident
    resident_year
      }
    }
  `;
  };
  GetProfileUser.prototype = queryProto;
  GetProfileUser.prototype.Constructor = GetProfileUser;

  const EditProfile = function (
    userID,
    currentEmail,
    email,
    name,
    lastName,
    secondLastName,
    specialtyID,
    countryID,
    stateID,
    license,
    phone,
    workingID,
    institutionID,
    otherInstitution,
    medicalUnits,
    sanitary,
    working_place_text,
    is_resident,
    resident_year
  ) {
    this.query = `mutation {
      EditProfile(
        id: ${userID}

        current_email:"${currentEmail}"

        email: "${email}"
        name: "${name}"
        last_name: "${lastName}"
        second_last_name: "${secondLastName}"


        specialty_id: "${specialtyID}"
        country_id: "${countryID}"
        state_id: "${stateID}"
        license: "${license}"
        phone: "${phone}"
        working_place_id: "${workingID}"


        institution_id: "${institutionID}"
        other_institution: "${otherInstitution}"
        medical_units: "${medicalUnits}"
        sanitary_jurisdiction_id: "${sanitary}"

        is_phone_required: true
        working_place_text: "${working_place_text}"

        is_resident: ${is_resident}
        resident_year: "${resident_year}"
      )
      {
        access_token
        refresh_token
        expires_in
        token_type
        status
        message
        user {
          id
        }
      }
    }
  `;
  };
  EditProfile.prototype = queryProto;
  EditProfile.prototype.Constructor = EditProfile;

  const UpdatePassword = function (
    ID,
    currentPassword,
    password,
    passwordConfirmation
  ) {
    this.query = `mutation {
      UpdatePassword(
        id: ${ID}
        current_password: "${currentPassword}"
        password: "${password}"
        password_confirmation: "${passwordConfirmation}"
      ) {
            status
            message
            user {
              id
            }
          }
    }
  `;
  };
  UpdatePassword.prototype = queryProto;
  UpdatePassword.prototype.Constructor = UpdatePassword;

  const IsDiplomaAvailable = function (eventID) {
    this.query = `query {
      IsDiplomaAvailable(event_id: ${eventID}) {
        id
        is_diploma_released
      }
    }
  `;
  };
  IsDiplomaAvailable.prototype = queryProto;
  IsDiplomaAvailable.prototype.Constructor = IsDiplomaAvailable;

  const GetVideoBreakouts = function (eventID) {
    this.query = `query {
      GetVideoBreakouts(event_id: ${eventID}) {
        id
        slug
        title
        full_playback_id
        full_translated_playback_id
      }
    }
  `;
  };
  GetVideoBreakouts.prototype = queryProto;
  GetVideoBreakouts.prototype.Constructor = GetVideoBreakouts;

  const GetDiploma = function (ID) {
    this.query = `query {
      GetDiploma(id:${ID}) {
        id
        url
        pos_x
        pos_y
        font_size
        text
        color
        invoice_pos_x
        invoice_pos_y
        invoice_font_size
      }
    }
  `;
  };
  GetDiploma.prototype = queryProto;
  GetDiploma.prototype.Constructor = GetDiploma;

  const MarkSeriesDiplomaDownloaded = function (
    eventID,
    diplomaID,
    percentage
  ) {
    this.query = `mutation {
      MarkSeriesDiplomaDownloaded(
        event_id: ${eventID}
        diploma_id: ${diplomaID}
        percentage: ${percentage}
      )
    }
  `;
  };
  MarkSeriesDiplomaDownloaded.prototype = queryProto;
  MarkSeriesDiplomaDownloaded.prototype.Constructor =
    MarkSeriesDiplomaDownloaded;

  const MarkDiplomaDownloaded = function (eventID, diplomaID) {
    this.query = `mutation {
      MarkDiplomaDownloaded(
        event_id: ${eventID}
        diploma_id: ${diplomaID}
      )
    }
  `;
  };
  MarkDiplomaDownloaded.prototype = queryProto;
  MarkDiplomaDownloaded.prototype.Constructor = MarkDiplomaDownloaded;

  const MarkCourseDiplomaDownloaded = function (eventID, diplomaID, courseID) {
    this.query = `mutation {
      MarkCourseDiplomaDownloaded(
        event_id: ${eventID}
        diploma_id: ${diplomaID}
        course_id: ${courseID}
      )
    }
  `;
  };
  MarkCourseDiplomaDownloaded.prototype = queryProto;
  MarkCourseDiplomaDownloaded.prototype.Constructor =
    MarkCourseDiplomaDownloaded;

  const MarkVideoDiplomaDownloaded = function (videoId, courseId) {
    this.query = `
      mutation {
        marked: markVideoDiplomaDownloaded(video_id: ${videoId} course_id: ${courseId})
      }
    `;
  };
  MarkVideoDiplomaDownloaded.prototype = queryProto;
  MarkVideoDiplomaDownloaded.prototype.Constructor = MarkVideoDiplomaDownloaded;

  const MarkDiplomaDownloadedPDF = function (eventID, diplomaID, userID) {
    this.query = `mutation {
      MarkDiplomaDownloadedPDF(
        event_id: ${eventID}
        diploma_id: ${diplomaID}
        user_id: ${userID}
      )
    }
  `;
  };
  MarkDiplomaDownloadedPDF.prototype = queryProto;
  MarkDiplomaDownloadedPDF.prototype.Constructor = MarkDiplomaDownloadedPDF;

  const IsVideoBookmarked = function (videoID) {
    this.query = `query {
      IsVideoBookmarked( video_id: ${videoID} )
    }`;
  };
  IsVideoBookmarked.prototype = queryProto;
  IsVideoBookmarked.prototype.Constructor = IsVideoBookmarked;

  const GetEventStatus = function (eventID) {
    this.query = `query {
      getEventStatus(id: ${eventID}) {
        id
        status
      }
    }`;
  };
  GetEventStatus.prototype = queryProto;
  GetEventStatus.prototype.Constructor = GetEventStatus;

  const ToggleBookmark = function (videoID) {
    this.query = `mutation {
      ToggleBookmark(video_id: ${videoID})
    }`;
  };
  ToggleBookmark.prototype = queryProto;
  ToggleBookmark.prototype.Constructor = ToggleBookmark;

  //chat

  const EventExists = function (slug) {
    this.query = `query {
      EventExists(
        slug: "${slug}"
      ) {
        id
      }
    }
`;
  };
  EventExists.prototype = queryProto;
  EventExists.prototype.Constructor = EventExists;

  const ReadedFilterer = function (eventID) {
    this.query = `query {
      ReadedFilterer(
        event_id: ${eventID}
      ) {

            id
            message
            created_at

            user {
                id
                name
                last_name
            }
    }
    }
`;
  };
  ReadedFilterer.prototype = queryProto;
  ReadedFilterer.prototype.Constructor = ReadedFilterer;

  const ReadedSupport = function (eventID) {
    this.query = `query {
      ReadedSupport(
        event_id: ${eventID}
      ) {

            id
            message
            created_at

            user {
                id
                name
                last_name
            }
    }
    }
`;
  };
  ReadedSupport.prototype = queryProto;
  ReadedSupport.prototype.Constructor = ReadedSupport;

  const MessagesForFilterer = function (eventID) {
    this.query = `query {
      messagesForFilterer(
            orderBy: [
              {
                  column: "created_at"
                  order: DESC
              }
          ],
          event_id: ${eventID}
          has_support:false
          to_speaker: false
          to_user: null
          to_message: null
          mark_read: false

        ) {

              id
              message
              created_at

              user {
                  id
                  name
                  last_name

                  country {
                    name
                  }
              }
              video {
                id
                title
              }
      }
  }
`;
  };
  MessagesForFilterer.prototype = queryProto;
  MessagesForFilterer.prototype.Constructor = MessagesForFilterer;

  const MessagesForSpeaker = function (eventID, readed) {
    this.query = `query {
      messagesForSpeaker(
            orderBy: [
              {
                  column: "created_at"
                  order: DESC
              }
          ],
          event_id: ${eventID}
          to_speaker: 1
          mark_read: ${readed})
          {

              id
              message
              created_at

              user {
                  id
                  name
                  last_name

              }

              video {
                id
                title
              }
      }
  }
`;
  };
  MessagesForSpeaker.prototype = queryProto;
  MessagesForSpeaker.prototype.Constructor = MessagesForSpeaker;

  const MessagesForSupport = function (eventID, toMessage) {
    this.query = `query {
      messagesForSupport(
            orderBy: [
              {
                  column: "created_at"
                  order: DESC
              }
          ],
          event_id: ${eventID}
          has_support: 1
          to_message: null)
          {

              id
              message
              created_at

              user {
                  id
                  name
                  last_name

                  country {
                    name
                  }

              }

              video {
                id
                title
              }
      }
  }
`;
  };
  MessagesForSupport.prototype = queryProto;
  MessagesForSupport.prototype.Constructor = MessagesForSupport;

  const SendToUser = function (eventID, message, toUserID, messageID) {
    this.query = `mutation {
      SendToUser(
        event_id: "${eventID}"
        message: "${message}"
        to_user_id: "${toUserID}"
        message_id: "${messageID}"
      ){
        id
         message
         user {
           id
           name
        }
      }
    }
`;
  };
  SendToUser.prototype = queryProto;
  SendToUser.prototype.Constructor = SendToUser;

  const SendToFilterer = function (eventID, message, videoId = null) {
    this.query = `mutation {
      SendToFilterer(
        event_id: "${eventID}"
        message:"${message}"
        video_id: ${videoId} 
      ){
        id
         message
         user {
           id
           name
        }
      }
    }
`;
  };
  SendToFilterer.prototype = queryProto;
  SendToFilterer.prototype.Constructor = SendToFilterer;

  const SendToSupport = function (messageID, userID) {
    this.query = `mutation{
      SendToSupport(
        message_id: "${messageID}"
        user_id: "${userID}"
      ) {
        id
        message
      }
    }`;
  };
  SendToSupport.prototype = queryProto;
  SendToSupport.prototype.Constructor = SendToSupport;

  const SendToSpeaker = function (messageID, userID) {
    this.query = `mutation{
      SendToSpeaker(
        message_id: "${messageID}"
        user_id: "${userID}"
      ) {
        id
        message
      }
    }`;
  };
  SendToSpeaker.prototype = queryProto;
  SendToSpeaker.prototype.Constructor = SendToSpeaker;

  const MarkAsRead = function (messageID) {
    this.query = `mutation{
      MarkAsRead(
        message_id: "${messageID}"
      ) {
        id
      }
    }`;
  };
  MarkAsRead.prototype = queryProto;
  MarkAsRead.prototype.Constructor = MarkAsRead;

  const Messages = function (eventID) {
    this.query = `query {
      MessagesByEvent(event_id: ${eventID}){
        id
        message
        user {
          id
          name
        }
      }
    }
  `;
  };
  Messages.prototype = queryProto;
  Messages.prototype.Constructor = Messages;
  //chat

  const ConfirmRegisterEvent = function (eventID, source, medium, campaign) {
    this.query = `mutation {
      ConfirmRegisterEvent(
        event_id: ${eventID}
        source: "${source}"
        medium: "${medium}"
        campaign: "${campaign}"
      )
    }`;
  };
  ConfirmRegisterEvent.prototype = queryProto;
  ConfirmRegisterEvent.prototype.Constructor = ConfirmRegisterEvent;

  const ConfirmRegisterProgram = function (courseId, source, medium, campaign) {
    this.query = `mutation {
      confirmRegisterProgram(
        course_id: ${courseId}
        source: "${source}"
        medium: "${medium}"
        campaign: "${campaign}"
      )
    }`;
  };
  ConfirmRegisterProgram.prototype = queryProto;
  ConfirmRegisterProgram.prototype.Constructor = ConfirmRegisterProgram;

  const HasConfirmed = function (eventID) {
    this.query = `query {
      HasConfirmed(
        event_id: ${eventID}
      )
    }`;
  };
  HasConfirmed.prototype = queryProto;
  HasConfirmed.prototype.Constructor = HasConfirmed;

  const MarkSurveyAsAnswered = function (eventID) {
    this.query = `mutation {
      MarkSurveyAsAnswered(
        event_id: ${eventID}
      )
    }`;
  };
  MarkSurveyAsAnswered.prototype = queryProto;
  MarkSurveyAsAnswered.prototype.Constructor = MarkSurveyAsAnswered;

  const MarkPodcasSurveyAsAnswered = function (podcastID) {
    this.query = `mutation {
      MarkPodcasSurveyAsAnswered(
        podcast_id: ${podcastID}
      )
    }`;
  };
  MarkPodcasSurveyAsAnswered.prototype = queryProto;
  MarkPodcasSurveyAsAnswered.prototype.Constructor = MarkPodcasSurveyAsAnswered;

  const HasAnsweredSurvey = function (eventID) {
    this.query = `query {
      HasAnsweredSurvey(event_id: ${eventID}) {
        id
        has_answered
      }
    }`;
  };
  HasAnsweredSurvey.prototype = queryProto;
  HasAnsweredSurvey.prototype.Constructor = HasAnsweredSurvey;

  const HasAnsweredPodcastSurvey = function (podcastID) {
    this.query = `query {
      HasAnsweredPodcastSurvey( podcast_id: ${podcastID} ) {
        id
        has_answered
      }
    }`;
  };
  HasAnsweredPodcastSurvey.prototype = queryProto;
  HasAnsweredPodcastSurvey.prototype.Constructor = HasAnsweredPodcastSurvey;

  const GetLastRealTimeCount = function (event_id) {
    this.query = `query {
      GetLastRealTimeCount(event_id: ${event_id}) {
        id
        count
      }
    }`;
  };
  GetLastRealTimeCount.prototype = queryProto;
  GetLastRealTimeCount.prototype.Constructor = GetLastRealTimeCount;

  // const GetRealTime = function (day, eventID) {
  //   this.query = `query {
  //     GetRealTime(
  //       day: ${day}
  //       event_id: ${eventID}
  //     ) {
  //       id
  //       at_minute
  //       count
  //       created_at
  //     }
  //   }`
  // }
  // GetRealTime.prototype = queryProto
  // GetRealTime.prototype.Constructor = GetRealTime

  const AddRealTime = function (minutesAgo, eventID) {
    this.query = `mutation {
      AddRealTime(
        minutes_ago: ${minutesAgo}
        event_id: ${eventID}
      )
    }`;
  };
  AddRealTime.prototype = queryProto;
  AddRealTime.prototype.Constructor = AddRealTime;

  const GetAttendedTotal = function (eventID) {
    this.query = `query {
      GetAttendedTotal(
        event_id: ${eventID}
      )
    }`;
  };
  GetAttendedTotal.prototype = queryProto;
  GetAttendedTotal.prototype.Constructor = GetAttendedTotal;

  // const GetRegisteredTotal = function (eventID) {
  //   this.query = `query {
  //     GetRegisteredTotal(
  //       event_id: ${eventID}
  //     )
  //   }`
  // }
  // GetRegisteredTotal.prototype = queryProto
  // GetRegisteredTotal.prototype.Constructor = GetRegisteredTotal

  // const GetTotalDownloadedDiplomas = function (eventID) {
  //   this.query = `query {
  //     GetTotalDownloadedDiplomas(event_id: ${eventID})
  //   }`
  // }
  // GetTotalDownloadedDiplomas.prototype = queryProto
  // GetTotalDownloadedDiplomas.prototype.Constructor = GetTotalDownloadedDiplomas

  const GetDashboardUsers = function (eventID) {
    this.query = `query {
      GetDashboardUsers(
        event_id: ${eventID}
      ) {
        id
        veeva_id
        nombre_completo
        cedula
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        tiempo_online
        tiempo_online_porcentaje
        constancias
        nombre_video
        dia
        territory_code
        bi_veeva_id
        specialty_code
        }
      }
    `;
  };
  GetDashboardUsers.prototype = queryProto;
  GetDashboardUsers.prototype.Constructor = GetDashboardUsers;

  const GetAllDashboardUsers = function (eventID) {
    this.query = `query {
      GetAllDashboardUsers(
        event_id: ${eventID}
      ) {
       
        veeva_id
        nombre_completo
        cedula
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        tiempo_online
        tiempo_online_porcentaje
        constancias
        nombre_video
        dia
          
        }
      }
    `;
  };
  GetAllDashboardUsers.prototype = queryProto;
  GetAllDashboardUsers.prototype.Constructor = GetAllDashboardUsers;

  const GetDashboardUsersTotal = function (eventID) {
    this.query = `query {
      GetDashboardUsersTotal(
        event_id: ${eventID}
      )
    }
    `;
  };
  GetDashboardUsersTotal.prototype = queryProto;
  GetDashboardUsersTotal.prototype.Constructor = GetDashboardUsersTotal;

  const GetEvent = function (eventID) {
    this.query = `query {
      getEvent( id: ${eventID} ) {
        id
        title
        start_date
        finish_date
        day
        duration
      }
    }
    `;
  };
  GetEvent.prototype = queryProto;
  GetEvent.prototype.Constructor = GetEvent;

  const GetEventsListInReports = function () {
    this.query = `query {
      eventsListInReports: getEventsListInReports {
        id
        title
        slug
        total_videos
      }
    }`;
  };
  GetEventsListInReports.prototype = queryProto;
  GetEventsListInReports.prototype.Constructor = GetEventsListInReports;

  const GetEventsList = function () {
    this.query = `
    query {
      GetEventsList {
        id
        title
        slug
        start_date
        start_date_string
        info_title {
          title
          slug
        }
        info_date {
          start_date
          start_date_string
        }
      }
    }
    
    `;
  };
  GetEventsList.prototype = queryProto;
  GetEventsList.prototype.Constructor = GetEventsList;

  const GetDownloadedDiplomasInEvent = function (eventID) {
    this.query = `query {
      GetDownloadedDiplomasInEvent(
        event_id: ${eventID}
      )
    }
    `;
  };
  GetDownloadedDiplomasInEvent.prototype = queryProto;
  GetDownloadedDiplomasInEvent.prototype.Constructor =
    GetDownloadedDiplomasInEvent;

  // const GetStatisticAgent = function (eventID, value, day = null) {
  //   this.query = `query {
  //     GetStatisticAgent(
  //      event_id: ${eventID}
  //      value: "${value}"
  //      day: ${day}
  //    ) {
  //      value
  //      total
  //    }
  //  }
  //   `
  // }
  // GetStatisticAgent.prototype = queryProto
  // GetStatisticAgent.prototype.Constructor = GetStatisticAgent

  const GetStatisticUser = function (eventID, value, field, day = null) {
    this.query = `query {
      GetStatisticUser(
         event_id: ${eventID}
        value: "${value}"
        field: "${field}"
        day: ${day}
      ) {
        value
        total
      }
    }
    `;
  };
  GetStatisticUser.prototype = queryProto;
  GetStatisticUser.prototype.Constructor = GetStatisticUser;

  // const GetTotalMessages = function (eventID) {
  //   this.query = `query {
  //     GetTotalMessages(event_id: ${eventID})
  //   }
  //   `
  // }
  // GetTotalMessages.prototype = queryProto
  // GetTotalMessages.prototype.Constructor = GetTotalMessages

  // const GetMaxConnected = function (eventID) {
  //   this.query = `query {
  //     GetMaxConnected(event_id: ${eventID})
  //   }
  //   `
  // }
  // GetMaxConnected.prototype = queryProto
  // GetMaxConnected.prototype.Constructor = GetMaxConnected

  const IsNewDiploma = function (eventID, specialtyID) {
    this.query = `query {
      IsNewDiploma(
        event_id: ${eventID}
        specialty_id: ${specialtyID}
      )
    }
    `;
  };
  IsNewDiploma.prototype = queryProto;
  IsNewDiploma.prototype.Constructor = IsNewDiploma;

  const SpecialtyHasExam = function (eventID, specialtyID) {
    this.query = `
    query {
      SpecialtyHasExam(
        event_id: ${eventID}
        specialty_id: ${specialtyID}
      )
    }
    `;
  };
  SpecialtyHasExam.prototype = queryProto;
  SpecialtyHasExam.prototype.Constructor = SpecialtyHasExam;

  const SpecialtyMustIgnoreInvoice = function (eventID, specialtyID) {
    this.query = `
    query {
      SpecialtyMustIgnoreInvoice(
        event_id: ${eventID}
        specialty_id: ${specialtyID}
      )
    }
    `;
  };
  SpecialtyMustIgnoreInvoice.prototype = queryProto;
  SpecialtyMustIgnoreInvoice.prototype.Constructor = SpecialtyMustIgnoreInvoice;

  const SendMedicalRequestInfo = function (
    name,
    lastName,
    secondLastName,
    specialtyId,
    license,
    description,
    email
  ) {
    // let name = arguments[0].dataForm.name
    // let lastName = arguments[0].dataForm.lastName
    // let secondLastName = arguments[0].dataForm.secondLastName
    // let specialtyId = arguments[0].dataForm.specialtyId
    // let license = arguments[0].dataForm.license
    // let description = arguments[0].dataForm.description
    // let email = arguments[0].dataForm.email

    this.query = `
    mutation {
      SendMedicalRequestInfo(
         name: "${name}"
          lastName: "${lastName}"
          secondLastName: "${secondLastName}"
          specialtyId: "${specialtyId}"
          license: "${license}"
          email: "${description}"
          description: "${email}"
      )
    }
    
    `;
  };
  SendMedicalRequestInfo.prototype = queryProto;
  SendMedicalRequestInfo.prototype.Constructor = SendMedicalRequestInfo;

  const IsRegional = function (eventID, stateID) {
    this.query = `query {
      IsRegional(
        event_id: ${eventID}
        state_id: ${stateID}
        ) {
          is_regional
          playback_id
          full_playback_id
        }
     }
    `;
  };
  IsRegional.prototype = queryProto;
  IsRegional.prototype.Constructor = IsRegional;

  const getMyState = function (userID) {
    this.query = `query {
      user(id: ${userID}) {
        id
        state {
          id
        }
      }
    }
    `;
  };
  getMyState.prototype = queryProto;
  getMyState.prototype.Constructor = getMyState;

  const GetSpecialtyDiploma = function (eventID, specialtyID) {
    this.query = `query {
      GetSpecialtyDiploma(
        event_id: ${eventID}
        specialty_id: ${specialtyID}
      ) {
        id

        diploma {
          id
        }
      }
    }
    `;
  };
  GetSpecialtyDiploma.prototype = queryProto;
  GetSpecialtyDiploma.prototype.Constructor = GetSpecialtyDiploma;

  const GetNewLiveInvoice = function (eventID) {
    this.query = `query {
      GetNewLiveInvoice(
        event_id: ${eventID}
      ) {
        id
        value
      }
    }
    `;
  };
  GetNewLiveInvoice.prototype = queryProto;
  GetNewLiveInvoice.prototype.Constructor = GetNewLiveInvoice;

  const GetNewVodInvoice = function (eventID) {
    this.query = `query {
      GetNewVodInvoice(
        event_id: ${eventID}
      ) {
        id
        value
      }
    }
    `;
  };
  GetNewVodInvoice.prototype = queryProto;
  GetNewVodInvoice.prototype.Constructor = GetNewVodInvoice;

  const MarkInvoice = function (invoiceID, eventID) {
    this.query = `mutation {
      MarkInvoice(
        invoice_id: ${invoiceID}
        event_id: ${eventID}
      )
    }
    `;
  };
  MarkInvoice.prototype = queryProto;
  MarkInvoice.prototype.Constructor = MarkInvoice;

  const MarkInvoicePDF = function (invoiceID, userID, eventID) {
    this.query = `mutation {
      MarkInvoicePDF(
        invoice_id: ${invoiceID}
        user_id: ${userID}
        event_id: ${eventID}
        )
    }
    `;
  };
  MarkInvoicePDF.prototype = queryProto;
  MarkInvoicePDF.prototype.Constructor = MarkInvoicePDF;

  const GetUserInvoice = function (eventID) {
    this.query = `query {
      GetUserInvoice(
        event_id: ${eventID}
      ) {
        id
        value
      }
    }
    `;
  };
  GetUserInvoice.prototype = queryProto;
  GetUserInvoice.prototype.Constructor = GetUserInvoice;

  const GetUserInvoicePDF = function (eventID, userID) {
    this.query = `query {
      GetUserInvoicePDF(
        event_id: ${eventID}
          user_id: ${userID}
      ) {
        id
        value
      }
    }
    `;
  };
  GetUserInvoicePDF.prototype = queryProto;
  GetUserInvoicePDF.prototype.Constructor = GetUserInvoicePDF;

  const GetUserSpecialty = function (userID) {
    this.query = `query {
      user( id: ${userID} ) {
        id
        specialty {
          id
        }
      }
    }
    `;
  };
  GetUserSpecialty.prototype = queryProto;
  GetUserSpecialty.prototype.Constructor = GetUserSpecialty;

  // const GetAttendedPercentage = function (eventID, attendedTotal) {
  //   this.query = `query {
  //     GetAttendedPercentage(
  //       event_id: ${eventID}
  //       attended_total: ${attendedTotal}
  //     )
  //   }
  //   `
  // }
  // GetAttendedPercentage.prototype = queryProto
  // GetAttendedPercentage.prototype.Constructor = GetAttendedPercentage

  const GetPodcastBySlug = function (eventSlug) {
    this.query = `query {
      GetPodcastBySlug(slug: "${eventSlug}") {
        id
        title
        slug
        banner_url
        publish_date
        published
        description
        thumbnail
        typeform_id
        total_episodes
      }
    }
    `;
  };
  GetPodcastBySlug.prototype = queryProto;
  GetPodcastBySlug.prototype.Constructor = GetPodcastBySlug;

  const CreatePodcastView = function (podcastID, episodeID, channel, duration) {
    this.query = `mutation {
      CreatePodcastView(
          podcast_id: ${podcastID}
          episode_id: ${episodeID}
          mode: "online"
          channel: "${channel}"
          duration: ${duration}
      )
    }
    `;
  };
  CreatePodcastView.prototype = queryProto;
  CreatePodcastView.prototype.Constructor = CreatePodcastView;

  const UpdatePodcastView = function (
    milliseconds,
    podcastID,
    episodeID,
    channel,
    duration,
    totalEpisodes,
    currentTime
  ) {
    console.log(
      "milliseconds, podcastID, episodeID, channel, duration, totalEpisodes, currentTime: ",
      milliseconds,
      podcastID,
      episodeID,
      channel,
      duration,
      totalEpisodes,
      currentTime
    );
    this.query = `mutation {
      UpdatePodcastView(
        milliseconds: ${milliseconds}
        podcast_id: ${podcastID}
        episode_id: ${episodeID}
        channel: "${channel}"
        mode: "online"
        duration: ${duration}
        total_episodes: ${totalEpisodes}
        current_time: ${currentTime}
      )
    }
    `;
  };
  UpdatePodcastView.prototype = queryProto;
  UpdatePodcastView.prototype.Constructor = UpdatePodcastView;

  const IsEpisodeBookmarked = function (episodeID) {
    this.query = `query {
      IsEpisodeBookmarked(
        episode_id: ${episodeID}
      )
    }
    `;
  };
  IsEpisodeBookmarked.prototype = queryProto;
  IsEpisodeBookmarked.prototype.Constructor = IsEpisodeBookmarked;

  const TogglePodcastBookmark = function (episodeID) {
    this.query = `mutation {
      TogglePodcastBookmark(
        episode_id: ${episodeID}
      )
    }
    `;
  };
  TogglePodcastBookmark.prototype = queryProto;
  TogglePodcastBookmark.prototype.Constructor = TogglePodcastBookmark;

  const GetPodcasts = function () {
    this.query = `query {
      GetPodcasts {
        id
        title
        slug
      	thumbnail
        likes
        
        podcast {
          slug
        }
  
        authors {
          name
        }
      }
    }
    `;
  };
  GetPodcasts.prototype = queryProto;
  GetPodcasts.prototype.Constructor = GetPodcasts;

  const GetAdminApiKey = function () {
    this.query = `query {
      GetAdminApiKey
    }
    `;
  };
  GetAdminApiKey.prototype = queryProto;
  GetAdminApiKey.prototype.Constructor = GetAdminApiKey;

  const GetTypeformResponse = function (typeformID, responseID) {
    this.query = `query {
      GetTypeformResponse(
        typeform_id: "${typeformID}"
        response_id: "${responseID}"
      )
    }
    `;
  };
  GetTypeformResponse.prototype = queryProto;
  GetTypeformResponse.prototype.Constructor = GetTypeformResponse;

  const SaveExamScore = function (eventID, examScore) {
    this.query = `mutation {
      SaveExamScore( 
        event_id: ${eventID}
        exam_score: ${examScore}
      )
    }
    `;
  };
  SaveExamScore.prototype = queryProto;
  SaveExamScore.prototype.Constructor = SaveExamScore;

  const GetExamTries = function (eventID) {
    this.query = `query {
      GetExamTries(
        event_id: ${eventID}
      ) {
          id
          exam_tries
        }
      }
    `;
  };
  GetExamTries.prototype = queryProto;
  GetExamTries.prototype.Constructor = GetExamTries;

  const HasAnsweredExam = function (eventID) {
    this.query = `query {
      HasAnsweredExam(event_id: ${eventID})
    }
    `;
  };
  HasAnsweredExam.prototype = queryProto;
  HasAnsweredExam.prototype.Constructor = HasAnsweredExam;

  const HasAprovedExam = function (eventID) {
    this.query = `query {
      HasAprovedExam(event_id: ${eventID})
    }
    `;
  };
  HasAprovedExam.prototype = queryProto;
  HasAprovedExam.prototype.Constructor = HasAprovedExam;

  // const GetPlayAverage = function (eventID, totalUsers) {
  //   this.query = `query {
  //     GetPlayAverage( event_id: ${eventID} total_users: ${totalUsers} )
  //   }
  //   `
  // }
  // GetPlayAverage.prototype = queryProto
  // GetPlayAverage.prototype.Constructor = GetPlayAverage

  // const GetRetention = function (event_id) {
  //   this.query = `query {
  //     GetRetention( event_id: ${event_id} )
  //   }
  //   `
  // }
  // GetRetention.prototype = queryProto
  // GetRetention.prototype.Constructor = GetRetention

  const GetCourseInfo = function (course_slug) {
    this.query = `query {
      GetCourseInfo(course_slug: "${course_slug}") {
        id
        slug
        title
        logo
        duration
        description
        min_play
        hours
        is_scheduled
        is_program
        is_module_blocked
        event {
          id
        }
        diploma {
          id
          url
          font_size
          pos_y
          pos_x
        }
        is_pharma
        course_rules
      }
    }
    `;
  };
  GetCourseInfo.prototype = queryProto;
  GetCourseInfo.prototype.Constructor = GetCourseInfo;

  const GetTotalCourseVideos = function (course_slug) {
    this.query = `query {
      GetTotalCourseVideos(course_slug: "${course_slug}")
    }
    `;
  };
  GetTotalCourseVideos.prototype = queryProto;
  GetTotalCourseVideos.prototype.Constructor = GetTotalCourseVideos;

  const GetCourseVideos = function (course_slug, exclude_video_id = 0) {
    this.query = `query {
      GetCourseVideos( course_slug: "${course_slug}" exclude_video_id: ${exclude_video_id} ) {
        id
        slug
        title
        thumbnail_url
        release_date
        is_completed

        event {
          slug
        }
        
        authors {
          name
        }
      }
    }
    `;
  };
  GetCourseVideos.prototype = queryProto;
  GetCourseVideos.prototype.Constructor = GetCourseVideos;

  const GetCourseVideosByModule = function (courseSlug, excludeVideoId = 0) {
    this.query = `
      query {
        courseVideos: GetCourseVideosByModule( course_slug: "${courseSlug}") {
          id
          title
          duration
          is_blocked
          course_videos(course_slug: "${courseSlug}" exclude_video_id: ${excludeVideoId}) {
            id
            slug
            title
            thumbnail_url
            release_date
            is_completed
            is_blocked
            likes
            authors {
              name
            }
            
            event {
              slug
            }
            
            authors {
              name
            }
          }
        }
      }
    `;
  };
  GetCourseVideosByModule.prototype = queryProto;
  GetCourseVideosByModule.prototype.Constructor = GetCourseVideosByModule;

  const GetCourseProgress = function (course_slug) {
    this.query = `
    query {
        GetCourseProgress(course_slug: "${course_slug}") 
     }
  
    `;
  };
  GetCourseProgress.prototype = queryProto;
  GetCourseProgress.prototype.Constructor = GetCourseProgress;

  const CanDownloadCourseDiploma = function (course_slug) {
    this.query = `
    query {
      CanDownloadCourseDiploma(course_slug: "${course_slug}")
    }
    `;
  };
  CanDownloadCourseDiploma.prototype = queryProto;
  CanDownloadCourseDiploma.prototype.Constructor = CanDownloadCourseDiploma;

  const GetNow = function () {
    this.query = `query {
      GetNow
    }
    `;
  };
  GetNow.prototype = queryProto;
  GetNow.prototype.Constructor = GetNow;

  const GetIppCountries = function () {
    this.query = `
    query {
      GetIppCountries {
        id
        slug
        flag_url
        country {
          name
        }
      }
    }
    `;
  };
  GetIppCountries.prototype = queryProto;
  GetIppCountries.prototype.Constructor = GetIppCountries;

  const GetFoldersBySlug = function (slug) {
    this.query = `
    query {
      GetFoldersBySlug(slug: "${slug}") {
        id
        title
        slug
        ipp_country {
          slug
        }
      }
    } 
    `;
  };
  GetFoldersBySlug.prototype = queryProto;
  GetFoldersBySlug.prototype.Constructor = GetFoldersBySlug;

  const GetProducts = function (slug) {
    this.query = `
    query {
      GetProducts(slug: "${slug}") {
        id
        title
        url
        updated_at
      }
    } 
    `;
  };
  GetProducts.prototype = queryProto;
  GetProducts.prototype.Constructor = GetProducts;

  const GetIppCountry = function (slug) {
    this.query = `
    query {
      GetIppCountry(slug: "${slug}") {
        show_folder
        country {
          name
        } 
      }
    } 
    `;
  };
  GetIppCountry.prototype = queryProto;
  GetIppCountry.prototype.Constructor = GetIppCountry;

  const GetFolderName = function (slug) {
    this.query = `
    query {
      GetFolderName(slug: "${slug}") {
        title
      }
    }
    `;
  };
  GetFolderName.prototype = queryProto;
  GetFolderName.prototype.Constructor = GetFolderName;

  const ShowPrescribingLink = function (userID) {
    this.query = `
    query {
      ShowPrescribingLink(user_id: ${userID}) {
        slug
      }
    } 
    `;
  };
  ShowPrescribingLink.prototype = queryProto;
  ShowPrescribingLink.prototype.Constructor = ShowPrescribingLink;

  const GetFirstFolder = function (country) {
    this.query = `
    query {
      GetFirstFolder(country: "${country}") {
        slug
      }
    }
    `;
  };
  GetFirstFolder.prototype = queryProto;
  GetFirstFolder.prototype.Constructor = GetFirstFolder;

  const CheckFolder = function (country, folder) {
    this.query = `
    query {
      CheckFolder(
        country: "${country}",
        folder: "${folder}"
      )
    }
    `;
  };
  CheckFolder.prototype = queryProto;
  CheckFolder.prototype.Constructor = CheckFolder;

  const CheckFolderExists = function (slug) {
    this.query = `
    query {
      CheckFolderExists(slug: "${slug}")
    }
    `;
  };
  CheckFolderExists.prototype = queryProto;
  CheckFolderExists.prototype.Constructor = CheckFolderExists;

  const GetFirstProgramEventVideo = function (course_slug) {
    this.query = `
    query {
      GetFirstProgramEventVideo(
        course_slug: "${course_slug}"
      ) {
        id
        title
        total_videos
        status
      }
    }
    `;
  };
  GetFirstProgramEventVideo.prototype = queryProto;
  GetFirstProgramEventVideo.prototype.Constructor = GetFirstProgramEventVideo;

  const GetProgramEventVideoBySlug = function (course_slug, video_slug) {
    this.query = `
    query {
      GetProgramEventVideoBySlug(
        course_slug: "${course_slug}"
        video_slug: "${video_slug}"
      ) {
        id
        title
        total_videos
        status
      }
    }
    `;
  };
  GetProgramEventVideoBySlug.prototype = queryProto;
  GetProgramEventVideoBySlug.prototype.Constructor = GetProgramEventVideoBySlug;

  const GetFirstProgramVideo = function (course_slug) {
    console.log("📐 course_slug: ", course_slug);
    this.query = `
    query {
      GetFirstProgramVideo(
        course_slug: "${course_slug}"
      ) {
        id
        title
        slug
        duration
        kaltura_id
        translated_kaltura_id
        full_kaltura_id
        full_translated_kaltura_id
        full_playback_id
        full_translated_playback_id
        thumbnail_url
        category {
          name
        }
        diploma {
          id
          url
          font_size
          pos_x
          pos_y
          color
        }
        min_play
        exam_typeform_id
      }
    }
    `;
  };
  GetFirstProgramVideo.prototype = queryProto;
  GetFirstProgramVideo.prototype.Constructor = GetFirstProgramVideo;

  const GetProgramVideoBySlug = function (course_slug, video_slug) {
    this.query = `
    query {
      GetProgramVideoBySlug(
        course_slug: "${course_slug}"
        video_slug: "${video_slug}"
      ) {
        id
        title
        slug
        duration
        kaltura_id
        translated_kaltura_id
        full_kaltura_id
        full_translated_kaltura_id
        full_playback_id
        full_translated_playback_id
        thumbnail_url
        category {
          name
        }
        diploma {
          id
          url
          font_size
          pos_x
          pos_y
          color
        }
        min_play
        exam_typeform_id
      }
    }
    `;
  };
  GetProgramVideoBySlug.prototype = queryProto;
  GetProgramVideoBySlug.prototype.Constructor = GetProgramVideoBySlug;

  const GetPrograms = function () {
    this.query = `
    query {
      GetPrograms {
        id
        title
        slug
        thumb_url
        hours
        total_courses
      }
    }
    `;
  };
  GetPrograms.prototype = queryProto;
  GetPrograms.prototype.Constructor = GetPrograms;

  const GetOtherPrograms = function () {
    this.query = `
    query {
      programs: getOtherPrograms {
        id
        title
        slug
        videos {
          id
          title
          slug
          thumb_url: thumbnail_url
          total_courses
        }
      }
    }
    `;
  };
  GetOtherPrograms.prototype = queryProto;
  GetOtherPrograms.prototype.Constructor = GetOtherPrograms;

  // const _= function () {
  //   this.query = `
  //   `
  // }
  // _.prototype = queryProto
  // _.prototype.Constructor = _

  const GetNewPrograms = function () {
    this.query = `
    query {
      GetNewPrograms {
        id
        title
        slug
        thumb_url
        hours
        total_courses
        is_program
      }
    }
    `;
  };
  GetNewPrograms.prototype = queryProto;
  GetNewPrograms.prototype.Constructor = GetNewPrograms;

  const SaveVideoScore = function (videoID, score, responseID) {
    this.query = `
    mutation {
      SaveVideoScore(
        video_id: ${videoID},
        score: ${score}
        response_id: "${responseID}"
      )
    }
    `;
  };
  SaveVideoScore.prototype = queryProto;
  SaveVideoScore.prototype.Constructor = SaveVideoScore;

  const SaveDownloadVideoDiploma = function (videoID) {
    this.query = `
    mutation {
      SaveDownloadVideoDiploma(
        video_id: ${videoID}
      )
    }
    `;
  };
  SaveDownloadVideoDiploma.prototype = queryProto;
  SaveDownloadVideoDiploma.prototype.Constructor = SaveDownloadVideoDiploma;

  const GetNewProgramEventVideoBySlug = function (courseSlug, videoSlug) {
    this.query = `
    query {
      GetNewProgramEventVideoBySlug(
        course_slug: "${courseSlug}"
        video_slug: "${videoSlug}"
      ) {
        id
        slug
        title
        type
        total_videos
        status

        diploma {
          id
        }
      }
    }
    `;
  };
  GetNewProgramEventVideoBySlug.prototype = queryProto;
  GetNewProgramEventVideoBySlug.prototype.Constructor =
    GetNewProgramEventVideoBySlug;

  const GetNewProgramVideoBySlug = function (courseSlug, videoSlug) {
    this.query = `
    query {
      GetNewProgramVideoBySlug(
        course_slug: "${courseSlug}"
        video_slug: "${videoSlug}"
      ) {
        id
        title
        slug
        full_playback_id
        full_translated_playback_id
        thumbnail_url
        duration
        exam_typeform_id
        category {
          name
        }
      }
    }
    `;
  };
  GetNewProgramVideoBySlug.prototype = queryProto;
  GetNewProgramVideoBySlug.prototype.Constructor = GetNewProgramVideoBySlug;

  const GetFirstNewProgramEventVideo = function (slug) {
    this.query = `
    query {
      GetFirstNewProgramEventVideo(
        course_slug: "${slug}"
      ) {
        id
        slug
        title
        type
        total_videos
        status
        diploma {
          id
        }
      }
    }
    `;
  };
  GetFirstNewProgramEventVideo.prototype = queryProto;
  GetFirstNewProgramEventVideo.prototype.Constructor =
    GetFirstNewProgramEventVideo;

  const GetFirstNewProgramVideo = function (slug) {
    this.query = `
    query {
      GetFirstNewProgramVideo(
        course_slug: "${slug}"
      ) {
         id
        title
        slug
        duration
        full_playback_id
        full_translated_playback_id
        thumbnail_url
        exam_typeform_id
        diploma {
          id
        }
        category {
          name
        }
      }
    }
    `;
  };
  GetFirstNewProgramVideo.prototype = queryProto;
  GetFirstNewProgramVideo.prototype.Constructor = GetFirstNewProgramVideo;

  const GetNewCourseVideos = function (slug, exclude_video_id) {
    this.query = `
    query {
      GetNewCourseVideos(
        course_slug: "${slug}"
        exclude_video_id: ${exclude_video_id}
      ) {
        id
        slug
        title
        thumbnail_url
        release_date: start_date
        locked
        progress
        exam_typeform_id
        is_blocked
        diploma {
          id
        }
        event {
          slug
        }
      }
    }
    `;
  };
  GetNewCourseVideos.prototype = queryProto;
  GetNewCourseVideos.prototype.Constructor = GetNewCourseVideos;

  const CanDownloadNewCourseDiploma = function (courseSlug) {
    this.query = `
    query {
      CanDownloadNewCourseDiploma(
        course_slug: "${courseSlug}"
      )
    }
    `;
  };
  CanDownloadNewCourseDiploma.prototype = queryProto;
  CanDownloadNewCourseDiploma.prototype.Constructor =
    CanDownloadNewCourseDiploma;

  const SaveDashboardAccess = function (email) {
    this.query = `mutation {
      SaveDashboardAccess(
        email: "${email}"
      )
    }`;
  };
  SaveDashboardAccess.prototype = queryProto;
  SaveDashboardAccess.prototype.Constructor = SaveDashboardAccess;

  const GetProperVipEvent = function (eventID) {
    this.query = `query {
      GetProperVipEvent(
        event_id: ${eventID}
      ) {
        status
        event {
          id
          slug
        }
      }
    }`;
  };
  GetProperVipEvent.prototype = queryProto;
  GetProperVipEvent.prototype.Constructor = GetProperVipEvent;

  const CreateVisit = function () {
    this.query = `mutation {
      CreateVisit {
        id
      }
    }`;
  };

  CreateVisit.prototype = queryProto;
  CreateVisit.prototype.Constructor = CreateVisit;

  const GetAppStoreUrl = function () {
    this.query = `query {
      getAppStoreUrl
    }`;
  };
  GetAppStoreUrl.prototype = queryProto;
  GetAppStoreUrl.prototype.Constructor = GetAppStoreUrl;

  const IsCurrentVideoApproved = function (courseId, videoId) {
    this.query = `query {
      isCurrentVideoApproved(
        course_id: ${courseId}
        video_id: ${videoId}
      )
    }`;
  };
  IsCurrentVideoApproved.prototype = queryProto;
  IsCurrentVideoApproved.prototype.Constructor = IsCurrentVideoApproved;

  const GetReportEventDetailViews = function (eventId, totalVideos = 0) {
    console.log("totalVideos: ", totalVideos);
    this.query = `query {
      detailViews: getReportEventDetailViews(event_id: ${eventId}) {
        id_open_data
        nombre_completo
        cedula
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        tiempo_online
        tiempo_online_porcentaje
        constancia
        source
        medium
        campaign
        ${totalVideos > 1 ? "video_title" : ""}
      }
    }`;
  };
  GetReportEventDetailViews.prototype = queryProto;

  const GetReportEventAccumulatedViews = function (eventId) {
    this.query = `query {
      accumulatedViews: getReportEventAccumulatedViews(event_id: ${eventId}) {
        id_open_data
        nombre_completo
        cedula
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        tiempo_online
        tiempo_online_porcentaje
        constancia
        source
        medium
        campaign
      }
    }`;
  };
  GetReportEventAccumulatedViews.prototype = queryProto;

  const GetReportMessages = function (eventId) {
    this.query = `query {
      message: getReportMessages(event_id: ${eventId}) {
        id_veeva
        nombre_completo
        cedula
        correo_electronico
        mensaje_recibido
        respuesta_enviada
        se_envio_al_speaker
        se_envio_al_soporte
        se_marco_como_leida
        fecha_hora_registro_mensaje
        ip
      }
    }`;
  };
  GetReportMessages.prototype = queryProto;

  const GetReportSurveys = function (eventId) {
    this.query = `query {
      surveys: getReportSurveys(event_id: ${eventId}) {
        veeva_id
        nombre_completo
        license
        phone
        email
        country_name
        state_name
        specialty_name
        working_place
        institution
        other_institution
        medical_units
        sanitary_jurisdiction_name
        verificado
        answers {
          question
          answer
        }
        ip
      }
    }`;
  };
  GetReportSurveys.prototype = queryProto;

  const GetReportRegistered = function () {
    this.query = `query {
      registered: getReportRegistered {
        veeva_id
        nombre
        apellido_paterno
        apellido_materno
        cedula
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        verificado
        plataforma
        fecha_de_registro 
        ip
      }
    }`;
  };
  GetReportRegistered.prototype = queryProto;

  const GetReportDiplomas = function () {
    this.query = `query {
      diplomas: getReportDiplomas {
        veeva_id
        nombre_completo
        cedula_profesional
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        verificado
        title
        fecha_descarga_constancia
        ip
      }
    }`;
  };

  GetReportDiplomas.prototype = queryProto;

  const GetReportVisits = function () {
    this.query = `query {
      visits: getReportVisits {
        veeva_id
        nombre
        apellido_paterno
        apellido_materno
        cedula
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        verificado
        fecha_de_registro
        sistema_operativo
        dispositivo
        navegador
        direccion_ip
        fecha_de_visita 
        ip
      }
    }`;
  };

  GetReportVisits.prototype = queryProto;

  const CreateVisitsTable = function () {
    this.query = `
    mutation {
      createVisitsTable
    }
    `;
  };

  CreateVisitsTable.prototype = queryProto;

  const GetReportViews = function () {
    this.query = `
        query {
          views: getReportViews {
            id_open_data
            nombre_completo
            cedula
            telefono
            correo
            pais
            estado
            especialidad
            lugar_de_trabajo
            institucion_pertenece
            otra_institucion
            unidad_medica
            jurisdiccion_sanitaria
            id_video
            nombre_video
            tiempo_duracion_video
            id_veeva_event
            evento
            tema
            marca_id
            marca
            reproducciones_realizadas
            tiempo_ultima_reproduccion
            porcentaje_de_reproduccion_total
            tipo_de_reproduccion
            fecha_de_ultima_reproduccion
            ip
        }
    }
    `;
  };

  GetReportViews.prototype = queryProto;

  const GetReportDetailViews = function () {
    this.query = `
    query {
      views: getReportDetailViews {
        id_open_data
        nombre_completo
        cedula
        telefono
        correo
        pais
        estado
        especialidad
        lugar_de_trabajo
        institucion_pertenece
        otra_institucion
        unidad_medica
        jurisdiccion_sanitaria
        id_video
        nombre_video
        tema
        marca_id
        marca
        tiempo_de_reproduccion_total
        percentage
        fecha_de_ultima_reproduccion
        hora_de_la_ultima_reproduccion
        tipo_de_reproduccion
        dispositivo
        navegador
        sistema_operativo
        ip
      }
    }
    `;
  };

  GetReportDetailViews.prototype = queryProto;
  GetReportDetailViews.prototype.Constructor = GetReportDetailViews;

  const GetReportDahsboard = function () {
    this.query = `query {
      dashboard: getReportDahsboard {
        nombre_completo
        telefono
        correo
        pais
        estado
        email_acceso
        fecha_ingreso
        ip
      }
    }`;
  };

  GetReportDahsboard.prototype = queryProto;
  const GetGraphContents = function () {
    this.query = `query {
      getGraphContents {
        id
        category
        event
        views
        date
      }
    }`;
  };
  GetGraphContents.prototype = queryProto;

  const GetGraphData = function (date, toDate) {
    this.query = `query {
      graphData: getGraphData(date: "${date}", ${
      toDate ? 'toDate: "' + toDate + '"' : "toDate: null"
    }) {
        view_id
        view_created_at
        formatted_view_created_at_by_day
        user_id
        user_name
        user_email
        user_specialty
        new_user_specialty
        user_country
        event_id
        event_date
        event_title
        event_slug
        event_category
        event_brands_string 
      }
    }`;
  };
  GetGraphData.prototype = queryProto;

  const GetTotalViewHoursByDate = function (date) {
    this.query = `query {
      hours: getTotalViewHoursByDate(date: "${date}")
    }`;
  };
  GetTotalViewHoursByDate.prototype = queryProto;

  const GetEventCurrentBreakoutGroup = function (eventId) {
    this.query = `query {
      currentBreakoutGroup: getEventCurrentBreakoutGroup(id: ${eventId}) {
        current_breakout_group
      }
    }`;
  };
  GetEventCurrentBreakoutGroup.prototype = queryProto;

  const CanDownloadVideoDiploma = function (videoId) {
    this.query = `query {
      canDownload: canDownloadVideoDiploma(video_id: ${videoId})
    }`;
  };
  CanDownloadVideoDiploma.prototype = queryProto;

  const GetEventMinPlay = function (eventId) {
    this.query = `query {
      eventMinPlay: getEventMinPlay(event_id: ${eventId})
    }`;
  };
  GetEventMinPlay.prototype = queryProto;

  const GetHomeData = function () {
    this.query = `
    query {
      topics: getTopics(orderBy: [{ column: "order", order: ASC }], available: 1) {
        id
        name
        slug
        image
      }
    
      categories: GetCategories {
        id
        name
        slug
        image
      }
    
      recentVideos: GetRecentVideos {
        id
        title
        slug
        thumbnail_url
        likes
        event_slug
        event_type
        authors {
          name
        }
      }
    
      vodEvents: GetVodEvents {
        id
        title
        slug
        poster_url
        total_videos
        diploma_type
        certification {
          id
          points(value: "points")
          titles: data(value: "title")
          images: data(value: "url")
        }
      }
    
      upcomingEvents: GetUpcomingEvents {
        id
        title
        slug
        upcoming_thumb_url
        start_date
        has_confirmed
        certification {
          id
          points(value: "points")
          titles: data(value: "title")
          images: data(value: "url")
        }
      }
    
      podcasts: GetPodcasts {
        id
        title
        slug
        thumbnail
        likes
    
        podcast {
          slug
        }
    
        authors {
          name
        }
      }
    
      mainSlider: GetCalendarSlider {
        id2
        title
        image
        link
        type
        status
      }
    
      clinicalCases: GetClinicalCases {
        id
        title
        slug
        abstract
        poster
      }
    
      programs: GetPrograms {
        id
        title
        slug
        thumb_url
        hours
        total_courses
      }
    
      otherPrograms: getOtherPrograms {
        id
        title
        slug
        videos {
          id
          title
          slug
          thumb_url: thumbnail_url
          total_courses
        }
      }
      seriesEvent: getSeriesEvent {
        slider_name
        slider_slug
        series_icon
        slug
        series_events {
          id
          title
          slug
          total
          poster_url
        }
      }

      pharmaProgram: getPharmaProgram {
        id
        title
        slug
        slider_name
        thumb_url
      }
    }

    `;
  };
  GetHomeData.prototype = queryProto;
  GetHomeData.prototype.Constructor = GetHomeData;

  const GetUserCountry = function (userId) {
    this.query = `query {
      user(id: ${userId}) {
       country {
         id
       }
     }
   }`;
  };
  GetUserCountry.prototype = queryProto;

  const GetDashboardReportData = function () {
    this.query = `
    query {
      countries: getCountries {
        name
      }
      states: getStates {
        name
      }
      institutions: getIntitutions {
        name
      }
      workingPlaces :getWorkingPlaces {
        name
      }
    }`;
  };
  GetDashboardReportData.prototype = queryProto;

  const HasUserPharmaProgramPermission = function (course_slug) {
    this.query = `
    query {
      hasUserPharmaProgramPermission(course_slug: "${course_slug}")
    }
    `;
  };
  HasUserPharmaProgramPermission.prototype = queryProto;

  const SaveBannerVisit = function (platform, bannerId) {
    this.query = `
    mutation {
      saveBannerVisit(platform: "${platform}", banner_id: ${bannerId})
    } 
    `;
  };
  SaveBannerVisit.prototype = queryProto;

  const GetVideoModule = function (videoId, courseId) {
    this.query = `
    query {
      videoModule: getVideoModule(video_id: ${videoId}, course_id: ${courseId}) {
        id
        title
        duration
        min_play
        diploma {
          id
          url
          font_size
          pos_x
          pos_y
        }
        is_blocked
      }
    }
     
    `;
  };
  GetVideoModule.prototype = queryProto;

  const CanDownloadModuleDiploma = function (moduleId) {
    this.query = `
    query {
      canDownloadModuleDiploma(module_id: "${moduleId}")
    }
    `;
  };
  CanDownloadModuleDiploma.prototype = queryProto;
  CanDownloadModuleDiploma.prototype.Constructor = CanDownloadModuleDiploma;

  const MarkModuleDiplomaDownloaded = function (moduleId, courseId) {
    this.query = `
    mutation {
      markModuleDiplomaDownloaded(module_id: ${moduleId} course_id: ${courseId})
    }
    `;
  };
  MarkModuleDiplomaDownloaded.prototype = queryProto;
  MarkModuleDiplomaDownloaded.prototype.Constructor =
    MarkModuleDiplomaDownloaded;

  const HasModulePermission = function (courseSlug, videoId) {
    this.query = `
    query {
      hasModulePermission(course_slug: "${courseSlug}", video_id: "${videoId}")
    }
     
    `;
  };
  HasModulePermission.prototype = queryProto;
  HasModulePermission.prototype.Constructor = HasModulePermission;

  const HasProgramPermission = function (courseSlug, videoSlug) {
    this.query = `
    query {
      hasProgramPermission(course_slug: "${courseSlug}", video_slug: "${videoSlug}")
    }
     
    `;
  };
  HasProgramPermission.prototype = queryProto;
  HasProgramPermission.prototype.Constructor = HasProgramPermission;

  const GetEventSeriesVideos = function (eventId, excludeVideoId) {
    this.query = `
    query {
      getEventSeriesVideos(event_id: ${eventId} exclude_video_id: ${excludeVideoId}) {
        id
        thumbnail_url
        title
        slug
      }
    } 
    `;
  };
  GetEventSeriesVideos.prototype = queryProto;
  GetEventSeriesVideos.prototype.Constructor = GetEventSeriesVideos;

  const GetEventByVideo = function (videoId) {
    this.query = `
    query {
      getEventByVideo(video_id: ${videoId}) {
        id
        title
        slug
        typeform_id
        diploma {
          id
        }
        total_videos
      }
    }
    `;
  };
  GetEventByVideo.prototype = queryProto;
  GetEventByVideo.prototype.Constructor = GetEventByVideo;

  const GetEventSlug = function (id) {
    this.query = `
      query {
        getEventSlug(id: ${id}) {
          title
          slug
          diploma_serie {
            id
            diploma {
              id
              url
              font_size
              color
              pos_x
              pos_y
            }
          }
        }
      }
    `;
  };
  GetEventSlug.prototype = queryProto;
  GetEventSlug.prototype.Constructor = GetEventSlug;

  const GetDiplomaDownloadsData = function (from, to = null, event_status) {
    this.query = `
      query {
        getDiplomaDownloadsData(from: "${from}", ${
      to ? 'to: "' + to + '"' : "to: null"
    }, event_status: "${event_status}"
    ) {
      id
      user {
        id
        country {
          id
          name
        }
        specialty {
          id
          name
        }
      }
      event {
        id
        title
      }
    }
  }
    `;
  };
  GetDiplomaDownloadsData.prototype = queryProto;
  GetDiplomaDownloadsData.prototype.Constructor = GetDiplomaDownloadsData;

  const GetTrafficVisitsData = function (from, to = null) {
    this.query = `
query {
  getTrafficVisitsData(
  from: "${from}", ${to ? 'to: "' + to + '"' : "to: null"}
  ) {
    id
    created_at
    user {
      id
      country {
        id
        name
      }
      specialty {
        id
        name
      }
    }
  }
}

    `;
  };
  GetTrafficVisitsData.prototype = queryProto;
  GetTrafficVisitsData.prototype.Constructor = GetTrafficVisitsData;

  const GetRegisteredUsersData = function (from, to = null) {
    this.query = `
    query {
  getRegisteredUsersData(
  from: "${from}", ${to ? 'to: "' + to + '"' : "to: null"}
  ) {
    id
    country {
      id
      name
    }
    specialty {
      id
      name
    }
  }
}


    `;
  };
  GetRegisteredUsersData.prototype = queryProto;
  GetRegisteredUsersData.prototype.Constructor = GetRegisteredUsersData;

  return {
    GetTopics,
    GetCourseById,
    GetModuleById,
    GetDiplomaVideo,
    GetCategories,
    GetLastEvents,
    GetLiveEvents,
    GetInstatagUser,
    IsEventInWhiteList,
    GetCalendarSlider,
    GetMainSlider,
    GetVodEvents,
    GetRecentVideos,
    GetUpcomingEvents,
    GetEventBySlug,
    GetUserPDF,
    GetEventBySlugPDF,
    GetVideoById,
    GetVideoEvent,
    GetVideoAuthors,
    GetRelatedVideos,
    GetFirstVideo,
    GetFirstEpisode,
    GetEventVideos,
    GetPodcastEpisodes,
    GetVideoBySlug,
    GetEpisodeBySlug,
    GetAssistances,
    GetBookmarks,
    GetPodcastBookmarks,
    GetContinueWatching,
    GetWatchedHistory,
    GetDownloadedDiplomas,
    GetAssistancesHome,
    GetRegisterDropdownValues,

    BrowseEvent,
    BrowseEventVideos,
    BrowseCategory,
    BrowseCategoryVideos,
    BrowseTopic,
    BrowseTopicVideos,
    BrowseCount,

    Login,
    Register,
    Logout,
    ForgotPassword,
    UpdateForgottenPassword,
    ResendConfirmationLink,
    VerifyEmail,

    GetGeneralDiplomaProgress,
    GetIndividualDiplomaProgress,
    GetClinicalCases,
    GetClinicalCaseBySlug,
    AlgoliaEventSearch,
    AlgoliaVideoSearch,
    GetCurrentTalk,
    GetCurrentTalkBreakout,
    CreateView,
    CreateDiplomaReport,
    GetLastCurrentTime,
    UpdateView,
    GetUser,
    GetProfileUser,
    EditProfile,
    UpdatePassword,
    IsDiplomaAvailable,
    GetVideoBreakouts,
    GetDiploma,
    MarkDiplomaDownloaded,
    MarkCourseDiplomaDownloaded,
    MarkVideoDiplomaDownloaded,
    MarkSeriesDiplomaDownloaded,
    IsVideoBookmarked,
    GetEventStatus,
    GetTotalViewHoursByDate,
    GetEventCurrentBreakoutGroup,
    ToggleBookmark,
    EventExists,
    ReadedFilterer,
    ReadedSupport,
    MessagesForFilterer,
    MessagesForSpeaker,
    MessagesForSupport,
    SendToUser,
    SendToFilterer,
    SendToSupport,
    SendToSpeaker,
    MarkAsRead,
    Messages,
    ConfirmRegisterEvent,
    ConfirmRegisterProgram,
    HasConfirmed,
    MarkSurveyAsAnswered,
    MarkPodcasSurveyAsAnswered,
    HasAnsweredSurvey,
    HasAnsweredPodcastSurvey,
    GetLastRealTimeCount,
    // GetRealTime,
    AddRealTime,
    GetAttendedTotal,
    // GetRegisteredTotal,
    // GetTotalDownloadedDiplomas,
    GetDashboardUsers,
    GetAllDashboardUsers,
    GetDashboardUsersTotal,
    GetEvent,
    GetEventsListInReports,
    GetEventsList,
    GetDownloadedDiplomasInEvent,
    // GetStatisticAgent,
    GetStatisticUser,
    // GetTotalMessages,
    // GetMaxConnected,
    IsNewDiploma,
    SpecialtyHasExam,
    SpecialtyMustIgnoreInvoice,
    SendMedicalRequestInfo,
    IsRegional,
    getMyState,
    GetSpecialtyDiploma,
    GetNewLiveInvoice,
    GetNewVodInvoice,
    MarkInvoice,
    MarkInvoicePDF,
    GetUserInvoice,
    GetUserInvoicePDF,
    GetUserSpecialty,
    // GetAttendedPercentage,
    GetPodcastBySlug,
    CreatePodcastView,
    UpdatePodcastView,
    IsEpisodeBookmarked,
    TogglePodcastBookmark,
    GetPodcasts,
    GetAdminApiKey,
    GetTypeformResponse,
    SaveExamScore,
    GetExamTries,
    HasAnsweredExam,
    HasAprovedExam,
    // GetPlayAverage,
    // GetRetention,
    GetCourseInfo,
    GetTotalCourseVideos,
    GetCourseVideos,
    GetCourseVideosByModule,
    GetCourseProgress,
    CanDownloadCourseDiploma,

    GetNow,
    IsEventSerie,
    GetAllSerieProgress,
    GetLiveAlatProgress,

    GetIppCountries,
    GetFoldersBySlug,
    GetProducts,
    GetIppCountry,
    GetFolderName,
    ShowPrescribingLink,
    GetFirstFolder,
    CheckFolder,
    CheckFolderExists,

    GetFirstProgramEventVideo,
    GetProgramEventVideoBySlug,
    GetFirstProgramVideo,
    GetProgramVideoBySlug,
    GetPrograms,
    GetOtherPrograms,

    GetNewPrograms,
    SaveVideoScore,
    SaveDownloadVideoDiploma,
    GetNewProgramEventVideoBySlug,
    GetNewProgramVideoBySlug,
    GetFirstNewProgramEventVideo,
    GetFirstNewProgramVideo,
    GetNewCourseVideos,
    CanDownloadNewCourseDiploma,
    SaveDashboardAccess,
    MarkDiplomaDownloadedPDF,
    GetProperVipEvent,
    CreateVisit,
    GetAppStoreUrl,
    IsCurrentVideoApproved,
    GetReportEventDetailViews,
    GetReportEventAccumulatedViews,
    GetReportMessages,
    GetReportSurveys,
    GetReportRegistered,
    GetReportDiplomas,
    GetReportVisits,
    CreateVisitsTable,
    GetReportViews,
    GetReportDetailViews,
    GetReportDahsboard,
    CanDownloadVideoDiploma,
    GetEventMinPlay,
    GetGraphContents,
    GetGraphData,
    GetHomeData,
    GetUserCountry,
    GetDashboardReportData,
    HasUserPharmaProgramPermission,
    SaveBannerVisit,
    GetVideoModule,
    CanDownloadModuleDiploma,
    MarkModuleDiplomaDownloaded,
    HasModulePermission,
    HasProgramPermission,
    GetEventSeriesVideos,
    GetEventByVideo,
    GetEventSlug,
    GetDiplomaDownloadsData,
    GetTrafficVisitsData,
    GetRegisteredUsersData,
  };
})();

export default api;
